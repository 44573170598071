import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['nextActionDate']

  connect() { }

  setNextActionDate(event) {
    // alert(event.currentTarget.dataset.nextDate)
    this.nextActionDateTarget.value = event.currentTarget.dataset.nextDate
  }

  // updateStatus(event) {
  //   var csrf = document.getElementsByName('csrf-token')[0].content;
  //   var record_model = event.currentTarget.dataset.recordModel;
  //   var record_id = event.currentTarget.dataset.recordId;
  //   var method = event.currentTarget.dataset.method
  //   var path = `${ event.currentTarget.dataset.updateUrl }&status=${ event.currentTarget.value }`;

  //   fetch(
  //     path, {
  //       method: method,
  //       dataType: 'script',
  //       credentials: 'include',
  //       headers: {
  //         'X-CSRF-Token': csrf
  //       }
  //     }
  //   );
  // }
}
