import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['']

  connect() {
  }

  showHideServiceDocumentFields(event) {
    var thisFile = event.currentTarget

    if (thisFile.dataset.maxFileSize > 0) {
      var maxFileBytes = thisFile.dataset.maxFileSize
    } else {
      var maxFileBytes = 2097152
    }

    var displayValue = Math.round(((maxFileBytes)/1000)/1000)

    var maxExceededMessage = `This file exceeds the maximum allowed file size (${ displayValue } MB)`
    var ary = thisFile.dataset.extensionWhitelist
    var allowedExtension = ary.split(',')
    var extErrorMessage = `Only image file with extension: ${ ary } are allowed`

    let extName = undefined
    let sizeExceeded = false
    let extError = false

    thisFile.files.forEach( (file) => {
      if ((file.size > 0) && (maxFileBytes > 0) && (parseInt(file.size) > parseInt(maxFileBytes))) {
        sizeExceeded = true;
      } else {
        sizeExceeded = false;
      }

      extName = file.name.split('.').pop();
      if (allowedExtension == '*') {
        return extError;
      } else if (allowedExtension.includes(extName.toLowerCase())) {
        return extError;
      } else {
        extError = true;
      }
    });

    if (sizeExceeded) {
      window.alert(maxExceededMessage);
      thisFile.value = '';
    }

    if (extError) {
      window.alert(extErrorMessage);
      thisFile.value = '';
    }

  }

}




