import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'rejectionNotice', 'approvalNote' ]

  connect() {
  }

  reject(event) {
    // const parent_row = event.currentTarget.parentNode.parentNode;
    const parent_row = document.getElementById(`user_${ event.currentTarget.dataset.userId }_payroll_row`)
    // console.log(`data-path: ${ event.currentTarget.getAttribute('data-path') }`)
    fetch(event.currentTarget.dataset.path)
      .then(response => response.text())
      .then(html => parent_row.innerHTML = html)
  }

  approve(event) {
    // const parent_row = event.currentTarget.parentNode.parentNode.parentNode.parentNode;
    const parent_row = document.getElementById(`user_${ event.currentTarget.dataset.userId }_payroll_row`)

    var note = ''

    if (this.hasApprovalNoteTarget) {
      var note = this.approvalNoteTarget.value
    }

    var path = event.currentTarget.getAttribute('data-path')

    if (note != '') {
      path = `${ path }&note=${ note }`
      // alert(path);
      fetch( path )
        .then(response => response.text())
        .then(html => parent_row.innerHTML = html)
    } else {
      fetch( path )
        .then(response => response.text())
        .then(html => parent_row.innerHTML = html)
    }

  }

  process_rejection_notice(event) {
    // event.preventDefault();
    let id = event.currentTarget.dataset.approvalId
    let input = document.getElementById(`rejection_notice_input_${ id }`)
    const rejection_notice = input.value
    const parent_card = event.currentTarget.parentNode.parentNode

    let csrf = document.getElementsByName("csrf-token")[0].content;
    let formData = new FormData()
    formData.append("pay_period_approval[rejection_notice]", rejection_notice);
    formData.append("pay_period_approval[id]", id);

    fetch(event.currentTarget.dataset.url, {
      body: formData,
      method: 'PATCH',
      dataType: 'script',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': csrf
      },
    }).then(function(response) {
      // console.log(`status: ${ response.status }`)
      if (response.status != 204) {
        input.classList.add('faded-bg-success')

        // console.log(`approval_id: ${ id }`)
        // console.log('rejection_notice', rejection_notice);
        // console.log(`hide the reject card`);
        // parent_card.classList.add('d-none');

        // console.log(`show the with_notice card with content '${ rejection_notice }'`);
        // console.log(`looking for rejection_notice_card_${ id }`);
        // console.log(`rejection_notice_card classlist ${ document.getElementById(`rejection_notice_card_${ id }`).classList }`);

        document.getElementById(`rejection_notice_card_${ id }`).classList.add('d-none');
        document.getElementById(`rejection_notice_${ id }_with_approve_wrapper`).classList.replace('d-none', 'd-block');
        document.getElementById(`rejection_notice_${ id }_with_approve`).innerText = rejection_notice;

      } else {
        input.classList.add('faded-bg-error')
      }
    })
  }

}
