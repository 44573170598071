import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'categorySelect', 'subCategorySelect', 'assetSelect', 'commDepartment', 'commType' ];

  // connect() {}

  get selectedDepartment() {
    return this.commDepartmentTarget.options[this.commDepartmentTarget.selectedIndex].value
  }

  reset() {
    this.categorySelectTarget.innerHTML = `<option value=''></option>`;
    this.subCategorySelectTarget.innerHTML = `<option value=''></option>`;
    this.categorySelectTarget.disabled = true;
    this.subCategorySelectTarget.disabled = true;
  }

  updateAssetOptions(event) {
    // let selected_department = this.commDescriptionTarget.options[this.commDescriptionTarget.selectedIndex].value
    let selected_family = event.currentTarget.options[event.currentTarget.selectedIndex].value
    this.assetSelectTarget.innerHTML = `<option value=''></option>`;

    if (selected_family.length === 'Select Asset') {
      this.assetSelectTarget.disabled = true;
      this.assetSelectTarget.innerHTML = `<option value=''></option>`;

    } else {
      var csrf = document.getElementsByName('csrf-token')[0].content;
      fetch(
        `/get_assets_from_family?family=${ selected_family }`, {
          method: 'GET',
          dataType: 'script',
          credentials: 'include',
          headers: {
            'X-CSRF-Token': csrf
          },
        }
      )
      .then((data) => data.text())
      .then((html) => {
        console.log(`html: ${ html }`)

      var json_array = JSON.parse(html);
        for(var i = 0; i < json_array.length; i++) {
          var d = json_array[i]
          this.assetSelectTarget.innerHTML += `<option value=${ d['id'] }>${ d['display_name'] }</option>`;
        }

            // this.assetSelectTarget.innerHTML += `<option value=${ html['id'] }>${ html['display_name'] }</option>`;
            // this.assetSelectTarget.insertAdjacentHTML("beforeend", `<option value=${ html['id'] }>${ html['display_name'] }</option>`);
          });

        }
  }

  updateCommType(event) {
    if (event.currentTarget.value.length === 0) {
      this.commTypeTarget.disabled = true;
      this.reset();
      this.commTypeTarget.value = '';

    } else {
      this.commTypeTarget.removeAttribute('disabled');
      this.commTypeTarget.value = '';
      this.categorySelectTarget.innerHTML = `<option value=''></option>`;
      this.categorySelectTarget.disabled = true;

      // if (this.categorySelectTarget.value === '') {
      //   this.categorySelectTarget.disabled = true;
      // } else {
      //   alert(`this.categorySelectTarget.value ${ this.categorySelectTarget.value }`)
      //   // this.categorySelectTarget.disabled = true;
      // }

      this.subCategorySelectTarget.innerHTML = `<option value=''></option>`;
      this.subCategorySelectTarget.disabled = true;
    }

  }

  updateCategory(event) {
    let selectedType = event.currentTarget.options[event.currentTarget.selectedIndex].value

    if (selectedType.length === 0) {
      this.reset();

    } else {
      var csrf = document.getElementsByName('csrf-token')[0].content;

      fetch(
        `/build_communication_categories?comm_type=${ selectedType }&selected_department=${ this.selectedDepartment }`, {
          method: 'GET',
          dataType: 'script',
          credentials: 'include',
          headers: {
            'X-CSRF-Token': csrf
          },
        }
      )
      .then((data) => data.text())
      .then((html) => {
        // console.log(html);
          this.insertCategoryOptions(html);
          // this.categorySelectTarget.removeAttribute('disabled');
          // this.subCategorySelectTarget.disabled = true;
      });

    }
  }

  updateSubCategories(event) {
    let selectedCategory = event.currentTarget.options[event.currentTarget.selectedIndex].value
    this.subCategorySelectTarget.removeAttribute('disabled');
    var csrf = document.getElementsByName('csrf-token')[0].content;

      fetch(
        `/build_communication_sub_categories?category=${ selectedCategory }&selected_department=${ this.selectedDepartment }`, {
          method: 'GET',
          dataType: 'script',
          credentials: 'include',
          headers: {
            'X-CSRF-Token': csrf
          },
        }
      )
      .then((data) => data.text())
      .then((html) => {
        console.log(`html: ${html}`)
          this.insertSubCategoryOptions(html);
          // this.categorySelectTarget.removeAttribute('disabled');
          // this.subCategorySelectTarget.disabled = true;
      });
  }

  titleCase(str) {
    var new_str = str.toLowerCase().split('_');
    for (var i = 0; i < new_str.length; i++) {
      new_str[i] = new_str[i].charAt(0).toUpperCase() + new_str[i].slice(1);
    }
    return new_str.join(' ');
  }

  insertCategoryOptions(data) {
    this.categorySelectTarget.removeAttribute('disabled');
    // reset the category input to be a single blank option
    this.categorySelectTarget.innerHTML = `<option value=''></option>`;

    var json_array = JSON.parse(data);
    for(var i = 0; i < json_array.length; i++) {
      var d = json_array[i]
      this.categorySelectTarget.innerHTML += `<option value=${ d }>${ this.titleCase(d) }</option>`;
    }
  }

  insertSubCategoryOptions(data) {
    this.subCategorySelectTarget.removeAttribute('disabled');
    // reset the category input to be a single blank option
    this.subCategorySelectTarget.innerHTML = `<option value=''></option>`;

    var json_array = JSON.parse(data);
    for(var i = 0; i < json_array.length; i++) {
      var d = json_array[i]
      this.subCategorySelectTarget.innerHTML += `<option value=${ d }>${ this.titleCase(d) }</option>`;
      // this.subCategorySelectTarget.disabled = false;
    }

  }

}
