import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'valueInput' ]

  // connect() {
  //   alert('boom')
  // }

  selectInput(event) {
    event.preventDefault();
    event.currentTarget.select()
  }

  loadMoneyValue(event) {
    // var val = event.currentTarget.options[event.currentTarget.selectedIndex].text.toLowerCase().split(' ').join('_')
    var val = event.currentTarget.options[event.currentTarget.selectedIndex].dataset.value

    // if (val === undefined) {
    if (val == null) {
      this.valueInputTarget.value = ''
    } else {
      this.valueInputTarget.value = val;
    }

  }

}

