import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['countryInput', 'existingCountry', 'timeZoneInput', 'existingTimeZone', 'stateInput', 'countryInputWrapper']
  static values = { clearTarget: String }

  // connect() { }

  // showHide() {
  //   if (this.hasCountryInputWrapperTarget) {
  //     if (this.stateInputTarget.value == 'INT') {
  //       this.countryInputWrapperTarget.classList.remove("d-none");
  //       this.countryInputWrapperTarget.classList.add("d-block");
  //     } else {
  //       this.countryInputWrapperTarget.classList.remove("d-block");
  //       this.countryInputWrapperTarget.classList.add("d-none");
  //     }
  //   }
  // };

  // clear(event) {
  //   event.preventDefault();
  //   console.log(`this.clearTargetValue: ${ this.clearTargetValue }`)

  //    var select = document.getElementById(`${ this.clearTargetValue }_select`)
  //    var change = new Event('change');
  //    select.value = null;
  //    select.dispatchEvent(change);
  // };

}
