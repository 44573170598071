import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'infoDescription' ]

  selectAllText(event) {
    // on click remove the default description
    this.infoDescriptionTarget.value = '';
  }

  replaceDescriptionIf() {
    if (this.infoDescriptionTarget.value === '') {
      // on blur and the field is blank, replace the default description
      this.infoDescriptionTarget.value = this.infoDescriptionTarget.dataset.description;
    } else {
      // on blur and the field is not blank, remove the target data-attribute so the field can no longer be affected by this controller
      this.infoDescriptionTarget.dataset.activityLogsTarget = '';
    }
  }

}




