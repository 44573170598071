import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['flight_type', 'return_leg_card']

  connect() {

    var card = document.getElementById("return_leg_card");

    if (this.flight_type === "round_trip") {
      card.classList.remove('closed');
      card.classList.add('opened');
    } else {
      card.classList.remove('opened');
      card.classList.add('closed');
    }

  }

  process_flight_type() {
    // instead of get by id...this should use a stimulus target
    var card = document.getElementById("return_leg_card");

    if (this.flight_type === "round_trip") {
      card.classList.remove('closed');
      card.classList.add('opened');
    } else {
      card.classList.remove('opened');
      card.classList.add('closed');
    }

  }

  get flight_type() {
    return this.flight_typeTarget.value
  }

}
