import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ['monthly_rate_flight_hours_base']

  connect() {
    // alert('connect');
  }

  // do_something(event) {
  //   var userId = event.currentTarget.dataset.userId;
  //   var url = event.currentTarget.dataset.url;
  //   var csrf = document.getElementsByName('csrf-token')[0].content;

  //   fetch(
  //     url, {
  //       method: 'POST',
  //       dataType: 'script',
  //       credentials: 'include',
  //       headers: {
  //         'X-CSRF-Token': csrf
  //       }
  //     }
  //   );

  // document.getElementById(`assignment_li_user_${ userId }`).classList.add('d-none');

  // }

  submit_form(event) {
    // alert('should submit');
    var userId = event.currentTarget.dataset.userId;
    document.getElementById(`assignment_form_user_${ userId }`).requestSubmit();
    document.getElementById(`assignment_li_user_${ userId }`).classList.add('d-none');
  }

}
