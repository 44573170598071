import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field", "latitude", "longitude" ]

  connect() {
    if (typeof(google) != "undefined") {
      this.initPlaces()
    }
  }

  initPlaces() {
    // this.map = new google.maps.Map(this.mapTarget, {
    //   center: new google.maps.LatLng(this.data.get("latitude") || 39.5, this.data.get("longitude") || -98.35),
    //   zoom: (this.data.get("latitude") == null ? 4 : 15)
    // })

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    // this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields(['place_id', 'geometry', 'icon', 'name'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

    // this.marker = new google.maps.Marker({
    //   map: this.map,
    //   anchorPoint: new google.maps.Point(0, -29)
    // })
  }

  placeChanged() {
    let place = this.autocomplete.getPlace()
    alert(`x: ${place.place_id}`)
    // if (!place.geometry) {
    //   window.alert(`No details available for input: ${place.name}`)
    //   return
    // }

    // if (place.geometry.viewport) {
    //   this.map.fitBounds(place.geometry.viewport)
    // } else {
    //   this.map.setCenter(place.geometry.location)
    //   this.map.setZoom(17)
    // }

    // this.marker.setPosition(place.geometry.location)
    // this.marker.setVisible(true)

    // this.latitudeTarget.value = place.geometry.location.lat()
    // this.longitudeTarget.value = place.geometry.location.lng()
  }

  // keydown(event) {
  //   if (event.key == "Enter") {
  //     event.preventDefault()
  //   }
  // }
}
