import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'anonymous', 'author', 'name', 'recipientList', 'recipientStandInText', 'assetFamily', 'recipientGroup', 'filterBox', 'communicationsRecipients' ]

  toggleRecipients() {
    const isChecked = event.currentTarget.checked
    if (isChecked) {

      this.filterBoxTargets.forEach( (el, i) => {
        el.checked = false;
      })

      this.communicationsRecipientsTarget.classList.add('invisible');
      this.communicationsRecipientsTarget.classList.remove('visible');

    } else {
      this.communicationsRecipientsTarget.classList.remove('invisible');
      this.communicationsRecipientsTarget.classList.add('visible');
    }
  }

  hide_user() {
    if (this.anonymousTarget.checked) {
      this.authorTarget.innerHTML = "it's a secret";
    } else {
      this.authorTarget.innerHTML = `${ this.author_name }`;
    }
  }

  update_recipient_list(event) {
    event.preventDefault();

    this.filterBoxTargets.forEach( (el, i) => {
      if (!el.disabled) {
        el.checked = false;
        el.parentNode.classList.remove('bg-light')
        el.parentNode.classList.remove('text-muted')
      }
    })

    let filterTerm = event.currentTarget.value;

    this.filterBoxTargets.forEach( (el, i) => {
      if (!el.disabled) {
        var elParent = el.parentNode
        var filterKeys = elParent.dataset.communicationFilters

        if (filterTerm == '' ) {
          el.checked = false;
        } else if (filterKeys.includes( filterTerm )) {
          el.checked = true;
        } else {
          elParent.classList.add('bg-light')
          elParent.classList.add('text-muted')
        }
      }

    })
  }

  update_recipient(event) {
    event.currentTarget.parentNode.classList.toggle('bg-light', !event.currentTarget.checked)
    event.currentTarget.parentNode.classList.toggle('text-muted', !event.currentTarget.checked)
  }

  // update_recipient_list(event) {
  //   // const user_scope = event.currentTarget.options[event.currentTarget.selectedIndex].value
  //   const user_scope = this.recipientGroupTarget.options[this.recipientGroupTarget.selectedIndex].value
  //   const asset_scope = this.assetFamilyTarget.options[this.assetFamilyTarget.selectedIndex].value
  //   const asset_scope_text = this.assetFamilyTarget.options[this.assetFamilyTarget.selectedIndex].text

  //   // console.log(`user_scope: ${ user_scope }`)
  //   // console.log(`asset_scope: ${ asset_scope }`)
  //   // console.log(`asset_scope_text: ${ asset_scope_text }`)

  //   let csrf = document.getElementsByName('csrf-token')[0].content;

  //   // var checkbox = document.createElement('input')
  //   // // Assigning the attributes
  //   // // to created checkbox
  //   // checkbox.type = "checkbox";
  //   // checkbox.name = "name";
  //   // checkbox.value = "value";
  //   // checkbox.id = "id";

  //   // // creating label for checkbox
  //   // var label = document.createElement('label');
  //   // label.htmlFor = "id";
  //   // label.appendChild(document.createTextNode('This is the label for checkbox.'));
  //   // // myDiv.appendChild(checkbox);
  //   // // myDiv.appendChild(label);

  //   fetch(`/lookup_user_list?user_scope=${ user_scope }&asset_family=${ asset_scope }`, {
  //     method: 'GET',
  //     credentials: 'include',
  //     headers: {
  //       'X-CSRF-Token': csrf
  //     },
  //   }).
  //   then(response => response.text()).
  //   then((html) => {
  //     this.recipientListTarget.innerHTML = `<li class='list-group-item'>...the following users will get notified about this communication (filtered to '${ asset_scope_text }' users)</li>`;
  //     let html_array = JSON.parse(html);
  //     console.log(`html_array: ${ html_array }`)
  //     for(var i = 0; i < html_array.length; i++) {
  //       var d = html_array[i]
  //       // console.log(`d: ${ d }`)
  //       this.recipientListTarget.innerHTML += `<li class='list-group-item'><input class='form-check-input boolean optional' type='checkbox' value='1' checked name='communication[recipient_ids][]'>${ d }</li>`;
  //     }
  //   });

  // }

  get anonymous_val() {
    return this.anonymousTarget.value
  }  

  get author_val() {
    return this.authorTarget.value
  }  

  get author_name() {
    return this.authorTarget.getAttribute("data-author-name")
  }

}
