import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

// Connects to data-controller="ts--select"
export default class extends Controller {
  static targets = ['select']

  connect() {
    this.selectTargets.forEach( (el, i) => {
      new Choices(el, {
        position: 'bottom'
      });
    })
  }

  validate(event) {
    this.selectTargets.forEach( (el, i) => {
      var length = el.options[el.selectedIndex].value

      if (length === '') {
        event.detail.formSubmission.stop();
        el.parentNode.classList.add('faded-bg-danger');
      }
    });
  }

}
