import { Controller } from "@hotwired/stimulus"
import { Dropzone } from "dropzone";
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ['input']
  static values = { filetypes: String }

  connect() {
    // dont show file input field
    this.inputTarget.disable = true
    this.inputTarget.style.display = 'none'

    var submitBtnEl = document.getElementById('dropzoneSubmit');
    if (submitBtnEl) {
      var originalBtnLabel = submitBtnEl.innerText;
    } else {
      var originalBtnLabel = 'Continue'
    }

    // alert(`submitBtnEl`);
    // console.log(`this.filetypesValue: ${ this.filetypesValue }`)

    Dropzone.autoDiscover = false;

    const dropzone = new Dropzone(this.element, {
      url: '/',
      maxFiles: 10,
      maxFilesize: 8,
      addRemoveLinks: true,
      resizeHeight: 50,
      resizeQuality: 0.1,
      dictDefaultMessage: 'Choose or drop files to upload',
      thumbnailWidth: 250,
      thumbnailHeight: 250,
      acceptedFiles: this.filetypesValue,
      autoQueue: false // autoQueue is dropzones auto-upload...we dont want that, we'll use ActiveStorage
    })

    dropzone.on("addedfile", file => {
      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url)
          upload.create((error, blob) => {
            this.hiddenInput = document.createElement("input")
            this.hiddenInput.type = "hidden"
            this.hiddenInput.name = this.inputTarget.name
            this.hiddenInput.value = blob.signed_id
            this.inputTarget.parentNode.insertBefore(this.hiddenInput, this.inputTarget.nextSibling)
            dropzone.emit("success", file)
            dropzone.emit("complete", file)
          })
        }
      }, 500)

      if (submitBtnEl) {
        submitBtnEl.classList.add('text-white');
        submitBtnEl.disabled = true;
        submitBtnEl.innerText = '...processing';
      }

      dropzone.on("complete", file => {
         submitBtnEl.disabled = false;
         submitBtnEl.classList.remove('text-white');
         submitBtnEl.innerText = originalBtnLabel;
      });

      dropzone.on("canceled", file => {
        // console.log("A file has been canceled");
         submitBtnEl.disabled = false;
         submitBtnEl.classList.remove('text-white');
         submitBtnEl.innerText = originalBtnLabel;
      });

    })

  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url')
  }
}
