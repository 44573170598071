import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'countDisplay']

  countDays(event) {
     const ONE_DAY = 1000 * 60 * 60 * 24;
     var date1 = Date.parse(this.startDateTarget.value);
     var date2 = Date.parse(this.endDateTarget.value);
     var numDays = Math.abs((date2 - date1) + ONE_DAY);
     var days = Math.floor(numDays / ONE_DAY);

     if (days > 1 || days == 0) {
        var label = 'DAYS'
     } else {
        var label = 'DAY'
     }

     this.countDisplayTarget.innerText = `SELECTED DATES SPAN ${ days } ${ label }`;
  }

}
