require("currency.js");

import { Controller } from "@hotwired/stimulus"
import currency from "currency.js"

export default class extends Controller {
  // static values = {
  //   periodFlightPayNum: Number
  // }

  static targets = [
    'actionAlert',
    'actionAlertTimeTracker',

    'totalTaxablePay',
    'totalNonTaxablePay',
    
    'reimbursableExpensesPay',

    'taxableElement',
    'nonTaxableElement',
    'flightPayElement',

    'hourlyPayElement',
    'periodHourlyPay',

    'dayRateElement',
    'totalDayRates',

    'extendedTimeElement',
    'periodExtendedPayInput',

    'weekOnePerDiem',
    'weekTwoPerDiem',
    'periodPerDiemPay',
    'periodTotalPay',
    'weekOneFlightPay',
    'weekTwoFlightPay',
    'periodFlightPay',

    'overtimePay',
    'periodOvertimePay',

    'nonTaxableTotalRow',
    'taxableTotalRow',
    'taxableErrorsRow',
    'taxableErrorsTd',
    'nonTaxableErrorsRow',
    'nonTaxableErrorsTd',
    'payrollSummaryTable',

    'showDayRatesWork'
  ]

  connect() {
    // console.log('test', 'connect')
    // DO NOT auto run the following if the payroll summary exists (is not action new), otherwise numbers are wrong
    if ( !this.payrollSummaryTableTarget.dataset.payrollSummaryId ) {
      // console.log('test', 'true');
      // on page load
      this.updateTotals()
    }

    if ( this.actionAlertTimeTrackerTarget.innerText.length < 1 ) {
      this.actionAlertTimeTrackerTarget.innerText = Date.now()
    }
  };

  updateTaxableTotals() {
    // console.log('updateTaxableTotals', `${}`);
    // this.updateActionAlert('updated taxable total')
    this.updateTotalTaxableInput;
    this.updatePeriodTotalInput();
  };

  updateHourlyTotal() {
    // console.log('test', 'updateHourlyTotal');
    // this.updateActionAlert('updated hourly total')
    this.updateTotalHourlyInput;
    this.updateTotalTaxableInput;
    this.updatePeriodTotalInput();
  };

  updatePeriodOvertimePay(event) {
    // alert('hit updatePeriodOvertimePay');
    var val = this.overtimeFieldsCurrencyValue
    // alert(`val = ${val}`);
    this.updateActionAlert(`updated period overtime (7h) to: ${ currency( val ) } (was ${ this.periodOvertimePayTarget.value })`)
    this.periodOvertimePayTarget.value = val;
    this.updateTaxableAndTotal(event);
  }

  updateNonTaxableTotals() {
    this.updateTotalNonTaxableInput;
    this.updatePeriodTotalInput();
  };

  updatePeriodTotalInput() {
    let val = this.totalTaxablePayCurrency.add(this.totalNonTaxablePayCurrency).value
    this.updateActionAlert(`updated period total to: ${ currency( val ) } (was ${ this.periodTotalPayTarget.value })`)
    return this.periodTotalPayTarget.value = val;
  };

  updateTotals() {
    // console.log('inside', 'updateTotals');
    // this.updateActionAlert('updated taxable & non-taxable total')
    this.updateTotalNonTaxableInput;
    this.updateTaxableTotals();
  };

  updateActionAlert(alert_text) {
    let currentDate = Date.now()
    let lastDate = parseInt( this.actionAlertTimeTrackerTarget.innerText )
    // let existingText = this.actionAlertTarget.innerHTML

    // console.log('tracker val', this.actionAlertTimeTrackerTarget.innerText)
    // console.log('currentDate', currentDate)
    // console.log('lastDate', lastDate)
    // console.log('currentDate - lastDate', currentDate - lastDate)
    // console.log('lastDate - currentDate', lastDate - currentDate)

    if ( (currentDate - lastDate) > 7000) {
      this.actionAlertTarget.innerHTML = ''
      this.actionAlertTimeTrackerTarget.innerText = currentDate
      this.actionAlertTarget.innerHTML = `<li>${ alert_text }</li>`
    } else {
      this.actionAlertTarget.innerHTML = `${ this.actionAlertTarget.innerHTML }<li>${ alert_text }</li>`
    }

  }

  get totalTaxablePayCurrency() {
    return currency(this.totalTaxablePayTarget.value);
  }

  get totalNonTaxablePayCurrency() {
    return currency(this.totalNonTaxablePayTarget.value);
  }

  get displayTaxableSumError() {
    var a = this.totalTaxablePayCurrency.value;
    var b = this.taxableFieldsCurrencyValue;

    if (a !== b) {

      this.taxableErrorsRowTarget.classList.remove('d-none');
      this.taxableErrorsRowTarget.classList.add('d-visible');
      this.taxableErrorsTdTarget.innerText = `WARNING - taxable fields sum ($${ b }) do not equal 'Taxable Total' ($${ a }).  Double check your math.`;

    } else if (a === b) {

      this.taxableErrorsRowTarget.classList.remove('d-visible');
      this.taxableErrorsRowTarget.classList.add('d-none');
      this.taxableErrorsTdTarget.innerText = ''

    }
  }

  get displayNonTaxableSumError() {
    var a = this.totalNonTaxablePayCurrency.value
    var b = this.nonTaxableFieldsCurrencyValue;

    if (a !== b) {

      this.nonTaxableErrorsRowTarget.classList.remove('d-none');
      this.nonTaxableErrorsRowTarget.classList.add('d-visible');
      this.nonTaxableErrorsTdTarget.innerText = `WARNING - non-taxable fields sum ($${ b }) do not equal 'Non-Taxable Total' ($${ a }).  Double check your math.`;

    } else if (a === b) {

      this.nonTaxableErrorsRowTarget.classList.remove('d-visible');
      this.nonTaxableErrorsRowTarget.classList.add('d-none');
      this.nonTaxableErrorsTdTarget.innerText = ''

    }
  }

  get weekOnePerDiemValue() {
    // this should eventually be changed to perDiemElement and get all elements and sum (like other methods in here)
    return currency(this.weekOnePerDiemTarget.value)
  }

  get weekTwoPerDiemValue() {
    return currency(this.weekTwoPerDiemTarget.value)
  }

  updatePerDiem() {
    var val = this.weekOnePerDiemValue.add(this.weekTwoPerDiemValue).value
    this.updateActionAlert(`updated per diem total to: ${ currency( val ) } (was ${ this.periodPerDiemPayTarget.value })`)
    this.periodPerDiemPayTarget.value = val;
    this.updateNonTaxableTotals();
  }

  sumExtendedPayElements() {
    let sum = 0;
    let extendedPayElements = this.extendedTimeElementTargets

    for(var i = 0; i < extendedPayElements.length; i++) {
      sum += currency(extendedPayElements[i].value).intValue;
    }

    return currency(sum, { fromCents: true }).value;
  }

  updateExtendedTimePay() {
    if (this.hasPeriodExtendedPayInputTarget) {
      var val = this.sumExtendedPayElements()
      this.updateActionAlert(`updated extended time pay to: ${ currency( val ) } (was ${ this.periodExtendedPayInputTarget.value })`)
      this.periodExtendedPayInputTarget.value = val;
      this.updateTaxableTotals();
    }
  }

  updateTaxableAndTotal(event) {
    console.log(`hit updateTaxableAndTotal`)
    var newVal = currency(event.target.value);
    var oldVal = currency(event.target.dataset.value);
    var oldTotal = currency(this.totalTaxablePayTarget.dataset.value);

    // console.log(`newVal: ${newVal}`)
    // console.log(`oldVal: ${oldVal}`)
    // console.log(`oldTotal: ${oldTotal}`)
    // console.log(`oldTotal == 0: ${oldTotal == 0}`)
    // console.log(`newVal.subtract(oldVal).add(oldTotal).value: ${newVal.subtract(oldVal).add(oldTotal).value}`)

    this.totalTaxablePayTarget.value = this.taxableFieldsCurrencyValue

    // if (oldTotal == 0) {
    //   this.totalTaxablePayTarget.value = this.taxableFieldsCurrencyValue
    // } else {
    //   this.totalTaxablePayTarget.value = newVal.subtract(oldVal).add(oldTotal).value;
    // }

    this.updatePeriodTotalInput();
    this.displayTaxableSumError;
  }

  updateNonTaxableAndTotal(event) {
    var newVal = currency(event.target.value);
    var oldVal = currency(event.target.dataset.value);
    var oldTotal = currency(this.totalNonTaxablePayTarget.dataset.value);

    if (oldTotal == 0) {
      this.totalNonTaxablePayTarget.value = this.nonTaxableFieldsCurrencyValue
    } else {
      this.totalNonTaxablePayTarget.value = newVal.subtract(oldVal).add(oldTotal).value;
    }

    this.updatePeriodTotalInput();
    this.displayNonTaxableSumError;
  }

  get nonTaxableFieldsCurrencyValue() {
    let sum = 0;

    let nonTaxableElements = this.nonTaxableElementTargets
    for(var i = 0; i < nonTaxableElements.length; i++) {
      sum += currency(nonTaxableElements[i].value).intValue;
    }

    return currency(sum, { fromCents: true }).value;
  }

  get updateTotalTaxableInput() {
    // console.log('updateTotalTaxableInput', `${ this.taxableFieldsCurrencyValue }`);
    var val = this.taxableFieldsCurrencyValue
    this.updateActionAlert(`updated taxable total to: ${ currency( val ) } (was ${ this.totalTaxablePayTarget.value })`)
    return this.totalTaxablePayTarget.value = val;
  }

  get updateTotalHourlyInput() {
    // console.log('updateTotalHourlyInput', `${ this.hourlyFieldsCurrencyValue }`);
    var val = this.hourlyFieldsCurrencyValue
    this.updateActionAlert(`updated hourly total to: ${ currency( val ) } (was ${ this.periodHourlyPayTarget.value })`)
    return this.periodHourlyPayTarget.value = val;
  }

  get updateTotalNonTaxableInput() {
    var val = this.nonTaxableFieldsCurrencyValue
    this.updateActionAlert(`updated non-taxable total to: ${ currency( val ) } (was ${ this.totalNonTaxablePayTarget.value })`)
    return this.totalNonTaxablePayTarget.value = val;
  }

  get taxableFieldsCurrencyValue() {
    let sum = 0;

    var taxableElements = this.taxableElementTargets
    for(var i = 0; i < taxableElements.length; i++) {
      console.log(`${ taxableElements[i].getAttribute('id') } -> ${ taxableElements[i].value }`)
      sum += currency(taxableElements[i].value).intValue;
    }

    return currency(sum, { fromCents: true }).value;
  };

  get hourlyFieldsCurrencyValue() {
    let sum = 0;

    var hourlyPayElements = this.hourlyPayElementTargets
    for(var i = 0; i < hourlyPayElements.length; i++) {
      sum += currency(hourlyPayElements[i].value).intValue;
    }
    // console.log('sum', sum);
    return currency(sum, { fromCents: true }).value;
  };

  get overtimeFieldsCurrencyValue() {
    let sum = 0;

    var overtimePayElements = this.overtimePayTargets
    for(var i = 0; i < overtimePayElements.length; i++) {
      sum += currency(overtimePayElements[i].value).intValue;
    }
    return currency(sum, { fromCents: true }).value;
  };

  sumDayRateFields() {
    let sum = 0;
    let valuesAry = [];

    if (this.hasTotalDayRatesTarget) {
      var dayRateElements = this.dayRateElementTargets;

      for(var i = 0; i < dayRateElements.length; i++) {
        valuesAry.push(dayRateElements[i].value)
        sum += currency(dayRateElements[i].value).intValue;
      }

      this.showDayRatesWorkTarget.innerText = valuesAry
      this.totalDayRatesTarget.value = currency(sum, { fromCents: true }).value;
      this.updateTaxableTotals();
    }
  };

  updatePeriodFlightPay() {
    let sum = 0;

    if (this.hasPeriodFlightPayTarget) {

      if (this.hasWeekOneFlightPayTarget) {
        sum += currency(this.weekOneFlightPayTarget.value).intValue;
      }

      if (this.hasWeekTwoFlightPayTarget) {
        sum += currency(this.weekTwoFlightPayTarget.value).intValue;
      }

      this.periodFlightPayTarget.value = currency(sum, { fromCents: true }).value;
      this.updateTaxableTotals();
    }

  };

  currentTotal() {
    return currency(this.periodTotalPayTarget.value).value
  }

  updatePeriodTotals(event) {
    var amt = currency(event.target.dataset.expenseAmount);
    var reimbursables = currency(this.reimbursableExpensesPayTarget.value);
    var nonTaxablePay = currency(this.totalNonTaxablePayTarget.value);
    var totalPay = currency(this.periodTotalPayTarget.value);

   if (event.target.checked) {

    var newReimursable = reimbursables.add(amt).value;
    var newNonTaxablePay = nonTaxablePay.add(amt).value;
    var newTotalPay = totalPay.add(amt).value;

  } else {

    var newReimursable = reimbursables.subtract(amt).value;
    var newNonTaxablePay = nonTaxablePay.subtract(amt).value;
    var newTotalPay = totalPay.subtract(amt).value;
  }

    this.reimbursableExpensesPayTarget.value = newReimursable;
    this.totalNonTaxablePayTarget.value = newNonTaxablePay;
    this.periodTotalPayTarget.value = newTotalPay;
  }

}
