import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [  ]

  // connect() {

  // }

  applyCredit(event) {
    let score, scoreValue;
    const initialScore = parseInt( document.getElementById('score_value').textContent );
    const existingRaItem = parseInt( document.getElementById(`penalty_for_selected_ra_item_${ event.currentTarget.dataset('selected-ra-item-id') }`).textContent );
    const penaltyCredit = parseInt( event.currentTarget.dataset('penalty-credit') );

    if ( event.currentTarget.checked = true ) {
      scoreValue = existingRaItem - penaltyCredit;
      score = initialScore - penaltyCredit;
    } else {
      scoreValue = existingRaItem + penaltyCredit;
      score = initialScore + penaltyCredit;
    }

    adjustProgressBar(score);
    go_no_go_display(score);
    document.getElementById(`penalty_for_selected_ra_item_${ event.currentTarget.data('selected-ra-item-id') }`).innerHTML(scoreValue)
    document.getElementById('risk_assessment_risk_score').value = score;
  }

  processMitigationCheck(event) {
    // alert('inside processCheck');
    let score, scoreValue;
    const initialScore = parseInt( this.riskScoreTarget.value );
    const existingRaItem = 0;
    const penaltyCredit = parseInt( event.currentTarget.dataset.penaltyCredit );

    // console.log(`existingRaItem: ${existingRaItem}`);
    // console.log(`penaltyCredit: ${penaltyCredit}`);
    // console.log(`initialScore: ${initialScore}`);

    if (event.currentTarget.checked) {
      console.log('this shit is checked');
      scoreValue = existingRaItem - penaltyCredit;
      score = initialScore - penaltyCredit;
    } else {
      scoreValue = existingRaItem + penaltyCredit;
      score = initialScore + penaltyCredit;
    }

    console.log(`scoreValue: ${scoreValue}`);
    console.log(`score: ${score}`);

    this.adjustProgressBar(score);
    this.goNoGoDisplay(score);
    document.getElementById(`penalty_for_selected_ra_item_${ event.currentTarget.dataset('selected-ra-item-id') }`).innerHTML(scoreValue);
    document.getElementById('risk_assessment_risk_score').value = score;
  }

}
