import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['vehicleNote', 'vehicleSelect']

  connect() {
    var selected = this.vehicleSelectTarget.options[this.vehicleSelectTarget.selectedIndex].value
    if (selected.length === 0 && this.hasVehicleNoteTarget) {
      this.vehicleNoteTarget.classList.add('d-none');
    } else if (this.hasVehicleNoteTarget) {
      this.vehicleNoteTarget.classList.remove('d-none');
    }
  }

  toggleVehicleNote(event) {
    // this.toggle(event);
    var selected = event.currentTarget.options[event.currentTarget.selectedIndex].value
    if (selected.length === 0 && this.hasVehicleNoteTarget) {
      this.vehicleNoteTarget.classList.add('d-none');
    } else if (this.hasVehicleNoteTarget) {
      this.vehicleNoteTarget.classList.remove('d-none');
    }
  }

  toggle(event) {
    var selected = event.currentTarget.options[event.currentTarget.selectedIndex].value
    if (selected.length === 0 && this.hasVehicleNoteTarget) {
      this.vehicleNoteTarget.classList.add('d-none');
    } else if (this.hasVehicleNoteTarget) {
      this.vehicleNoteTarget.classList.remove('d-none');
    }
  }

}
