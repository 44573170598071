import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'source',
    'filterable',
    'aircraftId',
    'userId',
    'projectId',
    'operationId',
    'customerId',
    'vehicleId',
    'actingAs'
  ]

  connect() {
    this.filterLogs();
  }

  filterLogs() {
    let selectedFilters = []
    let selectedAircraft = this.aircraftIdTarget.options[this.aircraftIdTarget.selectedIndex].value
    let selectedVehicle = this.vehicleIdTarget.options[this.vehicleIdTarget.selectedIndex].value
    let selectedUser = this.userIdTarget.options[this.userIdTarget.selectedIndex].value
    let selectedProject = this.projectIdTarget.options[this.projectIdTarget.selectedIndex].value
    let selectedOperation = this.operationIdTarget.options[this.operationIdTarget.selectedIndex].value
    let selectedCustomer = this.customerIdTarget.options[this.customerIdTarget.selectedIndex].value
    let selectedActingAs = this.actingAsTarget.options[this.actingAsTarget.selectedIndex].value

    if (selectedVehicle.length != 0) {
      selectedFilters.push(selectedVehicle)
    }

    if (selectedAircraft.length != 0) {
      selectedFilters.push(selectedAircraft)
    }

    if (selectedUser.length != 0) {
      selectedFilters.push(selectedUser)
    }

    if (selectedProject.length != 0) {
      selectedFilters.push(selectedProject)
    }

    if (selectedOperation.length != 0) {
      selectedFilters.push(selectedOperation)
    }

    if (selectedCustomer.length != 0) {
      selectedFilters.push(selectedCustomer)
    }

    if (selectedActingAs.length != 0) {
      selectedFilters.push(selectedActingAs)
    }

    this.filterableTargets.forEach( (el, i) => {
      let array2 = el.dataset.filters
      el.classList.toggle('d-none', !selectedFilters.every(v => array2.includes(v)))
    })
  }

  removeFilter(event) {
    event.preventDefault();
       this.filterableTargets.forEach( (el, i) => {
      el.classList.remove('d-none')
    })
  }

}
