import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="location-lookup"
export default class extends Controller {
  static targets = ['content', 'fromid']

  lookupLocation(event) {
    var to_id = event.currentTarget.value
    var from_id = this.fromidTarget.value

    // alert(`to_id: ${ to_id }, from_id: ${ from_id }`);
    fetch(`/locations/${ to_id }/return_location_display?from_id=${ from_id }`, { headers: { accept: "application/json" } })
    .then(response => response.json())
      .then(html => this.contentTarget.innerHTML = html)
  }

}
