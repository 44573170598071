import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['passFail', 'content', 'choiceBox', 'answerReward', 'answersCard', 'tidbitType', 'showMe', 'tidbitFrame']
  static values = { correct: String }

  // connect() {
  //   alert('hit tidbits Controller')
  // }

  toggleAnswers(event) {
    const selectVal = event.currentTarget.options[event.currentTarget.selectedIndex].value
    // console.log(`selectVal: ${ selectVal }`)
    // console.log(`this.answersCardTarget.value ${ this.answersCardTarget.value }`)
    if (selectVal == 'question') {
      this.answersCardTarget.classList.remove('invisible')
      this.answersCardTarget.classList.add('visible')
    } else {
      this.answersCardTarget.classList.add('invisible')
      this.answersCardTarget.classList.remove('visible')
    }
  }

  dismiss(event) {
    this.tidbitFrameTarget.style.display = 'none'
  }

  recordSeen(event) {
    fetch(`/mark_seen?roger_tidbit_id=${ event.currentTarget.dataset.rogerTidbitId }&user_id=${ event.currentTarget.dataset.userId }`);
  }

  showContent(event) {
    this.contentTarget.classList.remove('invisible')
    this.contentTarget.classList.remove('visible')

    if (this.hasShowMeTarget) {
      this.showMeTarget.classList.add('d-none')
    }

    this.recordSeen(event)
  }

  showAnswers(event) {
    this.showContent(event);
    const correctChoice = event.currentTarget.getAttribute('data-tidbits-correct-value')

    var correctItems = Array("Shit, ya!  That's right!", "Nice Job!", "Booyah!  That's Correct!", "Boom!  Nice Job.");
    var correctAnswerText = correctItems[Math.floor(Math.random()*correctItems.length)];

    var incorrectItems = Array("That's not right.", "Whooops.  That's not right.", "Wrong!", "That is incorrect", "Shit!  Wrong answer.", "Better hit the books!  That's not the right answer.");
    var wrongAnswerText = incorrectItems[Math.floor(Math.random()*incorrectItems.length)];

    // const correctAnswerText = "Shit, ya!  That's right!";
    // const wrongAnswerText = "Damn!  That's not right.";

    if (correctChoice == 'true') {
      this.answerRewardTarget.innerText = correctAnswerText;
      this.answerRewardTarget.classList.add('text-success');
      this.recordSeen(event)
    } else {
      this.answerRewardTarget.innerText = wrongAnswerText;
      this.answerRewardTarget.classList.add('text-danger');
    }

    this.answerRewardTarget.classList.remove('invisible');
    this.answerRewardTarget.classList.add('visible');

    this.passFailTargets.forEach((el, i) => {
      if (event.currentTarget.checked) {
        el.classList.remove('invisible');
        el.classList.add('visible');
      } else {
        el.classList.remove('visible');
        el.classList.add('invisible');
      }
    });

    this.choiceBoxTargets.forEach((el, i) => {
      el.disabled = true;
    });

  }

}
