import { Controller } from "@hotwired/stimulus";
import EditorJs from "@editorjs/editorjs";
import Paragraph from "@editorjs/paragraph";
import AttachesTool from '@editorjs/attaches';
import Checklist from "@editorjs/checklist";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
// import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import Quote from "@editorjs/quote";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import NestedList from '@editorjs/nested-list';
import TextVariantTune from '@editorjs/text-variant-tune';
import IndentTune from 'editorjs-indent-tune';
// import DragDrop from 'editorjs-drag-drop';
// import Undo from 'editorjs-undo';

// Connects to data-controller="editor"
export default class extends Controller {
  static targets = ['communicationContent']

  // Connects to data-action="click->editor#save"
  connect() {
    // alert('hit this');

    const initialContent = this.getInitialContent();

    this.contentEditor = new EditorJs({
      // holder is the target element
      holder: this.communicationContentTarget,
      data: initialContent,
      tools: {
        header: { class: Header },
        quote: Quote,
        table: Table,
        underline: Underline,
        textVariant: TextVariantTune,
        indentTune: IndentTune,
        list: {
          class: NestedList,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered'
          },
          tunes: ['indentTune']
        },
        paragraph: {
          class: Paragraph,
          config: { inlineToolbar: true },
          tunes: ['indentTune']
        },
        // attaches: {
        //   class: AttachesTool
        //   // config: {
        //   //   endpoint: 'http://localhost:8008/uploadFile'
        //   // }
        // },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
          tunes: ['indentTune'],
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: 'upload_image'
            },
            additionalRequestHeaders: {
              "X-CSRF-Token": this.csrfToken(),
            }
          }
        },
        Marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+M',
        }
      },
      tunes: ['textVariant']
    });

    this.element.addEventListener('submit', this.saveEditorData.bind(this));
  }

  getInitialContent() {
    const hiddenContentField = document.getElementById('communication_editorjs');
    if (hiddenContentField && hiddenContentField.value) {
      return JSON.parse(hiddenContentField.value)
    } else {
      return {}
    }

  }

  async saveEditorData(event) {
    event.preventDefault();
    const outputData = await this.contentEditor.save();
    const articleForm = this.element;
    const hiddenInput = document.getElementById('communication_editorjs');

    hiddenInput.value = JSON.stringify(outputData);
    articleForm.submit();
  }

  csrfToken() {
    const metaTag = document.querySelector("meta[name='csrf-token']");
    return metaTag ? metaTag.content : "";
  }

}
