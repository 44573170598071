import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ['date_input']

  // connect() {
    // flatpickr("[data-behavior='flatpickr']", {
    //   altInput: true,
    //   altFormat: "F j, Y",
    //   dateFormat: "Y-m-d",
    // });
  // }

  clearDateFieldValue(event) {
    var targetFieldDomId = event.currentTarget.dataset.targetInputId
    // alert(targetFieldDomId);
    document.getElementById( targetFieldDomId ).value = ' ';
    // document.getElementById( targetFieldDomId ).classList.add('text-danger')
    // document.getElementById( targetFieldDomId ).value = this.defaultValue;
    // document.getElementById( targetFieldDomId ).value = null;
  }

}
