import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // connect() {
  //   alert('shit')
  // }

  reset() {
    this.element.reset()
  }

  disable() {
    var submitBtn = this.element.querySelector('input[type="submit"')
    submitBtn.disabled = true;
    submitBtn.value = '...saving'
  }

}
