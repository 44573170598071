import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ['']

  copyToClipbpard(event) {
    // console.log( event.currentTarget.text );
    event.preventDefault();
    // var val = event.currentTarget.text;
    var val = event.currentTarget.dataset.text;
    navigator.clipboard.writeText( val );
  }

}
