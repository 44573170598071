import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['value', 'param_name']

  connect() {
  }

  update_param_name() {
    // alert('new_val');
    var new_val = this.valueTarget.value.toLowerCase().split(' ').join('_');
    // alert(new_val);
    this.param_nameTarget.value = new_val
  }

}   
