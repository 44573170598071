import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['source', 'filterable']

  filterExpenses(event) {
    event.preventDefault();

    let lowerCaseFilterTerm = event.currentTarget.dataset.category;
    // console.log(`lowerCaseFilterTerm: ${ lowerCaseFilterTerm }`);
    // console.log(`this.filterableTargets length ${ this.filterableTargets }, ${ this.filterableTargets.length }`)
    this.filterableTargets.forEach( (el, i) => {
      let filterableKeys = el.dataset.filterKeys
      // console.log(`filterableKeys: ${ filterableKeys }`);
      el.classList.toggle('d-none', !filterableKeys.includes( lowerCaseFilterTerm ))
    })
  }

  // filterAircraftModelId(event) {
  //   event.preventDefault();

  //   let lowerCaseFilterTerm = event.currentTarget.dataset.aircraftModelFamily;

  //   this.filterableTargets.forEach( (el, i) => {
  //     let filterableKeys = el.dataset.filterKeys

  //     el.classList.toggle('d-none', !filterableKeys.includes( lowerCaseFilterTerm ))
  //   })

  // }

  removeFilter(event) {
    event.preventDefault();
       this.filterableTargets.forEach( (el, i) => {
      el.classList.remove('d-none')
    })
  }

  // scheduledSchedule(event) {
  //   event.preventDefault();

  //   this.filterableTargets.forEach( (el, i) => {
  //     let filterableKeys = el.dataset.filterKeys
  //     // alert(filterableKeys.length > 2);
  //     el.classList.toggle('d-none', !(filterableKeys.length > 2) )
  //   })
  // }

  // notScheduledSchedule(event) {
  //   event.preventDefault();

  //   this.filterableTargets.forEach( (el, i) => {
  //     let filterableKeys = el.dataset.filterKeys
  //     // alert(filterableKeys.length === 2);
  //     el.classList.toggle('d-none', !(filterableKeys.length < 3) )
  //   })
  // }

}


