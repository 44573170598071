import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['et_val_select']

  // connect() { }

  update_extended_time(event) {
    var thisSelect = event.currentTarget
    fetch(`/adjust_extended_time?daily_log_id=${ thisSelect.dataset.dailyLogId }&pay_period=${ thisSelect.dataset.payPeriod }&year=${ thisSelect.dataset.year }&extended_time=${ thisSelect.value }`)
  }

  get et_val_select() {
    return this.et_val_selectTarget.value
  }

}
