import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['source', 'filterable']

  filterByAircraftId(event) {
    event.preventDefault();
    let lowerCaseFilterTerm = `aircraft_${ event.currentTarget.dataset.aircraftId }`;

    // console.log(`lowerCaseFilterTerm: ${ lowerCaseFilterTerm }`);

    this.filterableTargets.forEach( (el, i) => {
      let filterableKeys = el.dataset.filterKeys
      // filterableKey.indexOf('scheduled') == -1
      // console.log(`filterByAircraftId = ${ filterableKeys }.includes("${ lowerCaseFilterTerm }") (${ filterableKeys.includes( lowerCaseFilterTerm ) })`);
      console.log(`filterByAircraftId = ${ filterableKeys }.indexOf("${ lowerCaseFilterTerm }") (${ filterableKeys.indexOf( lowerCaseFilterTerm ) })`);
      // el.classList.toggle('d-none', !filterableKeys.includes( lowerCaseFilterTerm ))
      el.classList.toggle('d-none', filterableKeys.indexOf( lowerCaseFilterTerm ) == -1)
    })
  }

  // filterCrewDesignation(event) {
  //   event.preventDefault();
  //   let lowerCaseFilterTerm = event.currentTarget.dataset.designation;

  //   this.filterableTargets.forEach( (el, i) => {
  //     let filterableKey = el.dataset.filterKeys
  //     el.classList.toggle('d-none', !filterableKey.includes( lowerCaseFilterTerm ))
  //   })
  // }

  filterAircraftModelId(event) {
    event.preventDefault();
    // let lowerCaseFilterTerm = event.currentTarget.dataset.aircraftModelFamily;
    let lowerCaseFilterTerm = `aircraft_model_family_${ event.currentTarget.dataset.aircraftModelFamilyId }`;
    // console.log(`${ lowerCaseFilterTerm }.includes("${ lowerCaseFilterTerm }") (${ lowerCaseFilterTerm.includes( lowerCaseFilterTerm ) })`);
    this.filterableTargets.forEach( (el, i) => {
      let filterableKey = el.dataset.filterKeys
      console.log(`filterAircraftModelId = ${ filterableKey }.indexOf(${ lowerCaseFilterTerm }) (${ filterableKey.indexOf( lowerCaseFilterTerm ) })`);
      el.classList.toggle('d-none', filterableKey.indexOf( lowerCaseFilterTerm ) == -1)
    })
  }

  filterProjectSchedule(event) {
    event.preventDefault();
    let lowerCaseFilterTerm = event.currentTarget.dataset.projectOperation;
    this.filterableTargets.forEach( (el, i) => {
      let filterableKey = el.dataset.filterKeys
      el.classList.toggle('d-none', filterableKey.indexOf( lowerCaseFilterTerm ) == -1)
    })
  }

  unfilterSchedule(event) {
    event.preventDefault();

    this.filterableTargets.forEach( (el, i) => {
      el.classList.remove('d-none')
    });

    // document.getElementById('filter_users').value = '';
    // document.getElementById('filter_users').blur;
  }

  scheduledSchedule(event) {
    event.preventDefault();

    this.filterableTargets.forEach( (el, i) => {
      let filterableKey = el.dataset.filterKeys
      console.log(`scheduledSchedule = filterableKey: ${ filterableKey } (${ filterableKey.indexOf('scheduled') })`);
      el.classList.toggle('d-none', filterableKey.indexOf('scheduled') == -1 );
    })
  }

  notScheduledSchedule(event) {
    event.preventDefault();

    this.filterableTargets.forEach( (el, i) => {
      let filterableKey = el.dataset.filterKeys
      // console.log(`filterableKey: ${ filterableKey } (${ filterableKey.indexOf('not_scheduled') })`);
      el.classList.toggle('d-none', filterableKey.indexOf('not_scheduled') == -1 );
    }) 
  }

  filter(event) {
    // let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase();
    let lowerCaseFilterTerm = event.currentTarget.value.toLowerCase();
    // console.log(`${ lowerCaseFilterTerm }`);
    this.filterableTargets.forEach((el, i) => {
      let filterableKey = el.getAttribute('data-filter-keys')

      el.classList.toggle('filter--notFound', filterableKey.indexOf( lowerCaseFilterTerm ) == -1 )
    })
  }

}


