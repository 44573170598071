import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'source', 'filterable' ]

  // connect() {
  //   alert('shit')
  // }

  filter(event) {
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase();
    // console.log(lowerCaseFilterTerm);
    this.filterableTargets.forEach((el, i) => {
      let filterableKey = el.getAttribute('data-filter-keys')
      // console.log(`filterableKey: ${ filterableKey }`)
      var does_not_include_source = !filterableKey.includes( lowerCaseFilterTerm )
      // console.log(`el.classList: ${ el.classList }`);
      // console.log(`${ does_not_include_source } = !${ filterableKey }.includes( ${ lowerCaseFilterTerm } )`);
      el.classList.toggle('filter--notFound', does_not_include_source )
    })
  }

  // clear(event) {
  //   this.sourceTarget.value = '';
  // }

}
