import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['day_rate_select', 'day_rate_value']

  connect() { }

  getValue(event) {
    // console.log(`event.currentTarget.selectedIndex :: ${ event.currentTarget.selectedIndex }`);
    if (event.currentTarget.selectedIndex == 0) {
      this.day_rate_valueTarget.value = ''
    } else {
      this.day_rate_valueTarget.value = event.currentTarget.options[event.currentTarget.selectedIndex].dataset.rateValue
    }
  }

}
