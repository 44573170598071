import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['first_name', 'last_name', 'username', 'first_initial']

  process_username() {

    if (this.username == '' && this.first_name != '') {
      this.usernameTarget.value = `${ this.first_initial }${ this.last_name }`;
    }

  }

  get username() {
    return this.usernameTarget.value
  }

  get first_name() {
    return this.first_nameTarget.value
  }

  get first_initial() {
    return this.first_nameTarget.value.toLowerCase().split('')[0]
  }

  get last_name() {
    return this.last_nameTarget.value.toLowerCase()
  }

}

