import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
// import { Sortable, MultiDrag, Swap, OnSpill, AutoScroll } from "sortablejs";

export default class extends Controller {
  // static targets = []

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      animation: 150,
      ghosting: true,
      ghostClass: 'list-group-item-danger',
      chosenClass: "sortable-chosen",
      dragClass: "sortable-drag"
      // hoverclass: 'bg-danger'
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let newIndex = event.newIndex // + 1
    // console.log(`id: ${ id }`)
    let data = new FormData()
    // console.log(`data: ${ data }`)
    // data.append("position", event.newIndex + 1)
    data.append('position', newIndex)
    // console.log(`append.position: ${ data }`)
    // console.log(`newIndex: ${ newIndex }`)
    var csrf = document.getElementsByName('csrf-token')[0].content;

    // console.log(`${ event.item.dataset.dragUrl }?position=${ newIndex }`)

    fetch(
      `${ event.item.dataset.dragUrl }?position=${ newIndex }`, {
        method: 'PATCH',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrf
        }
      }
    );

  }

}
