import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'inputSelect', 'inputCheckBox', 'driverChangeAlert', 'pilotChangeAlert' ]
  static values = { objectTarget: String }

  connect() {
    // alert(this.objectTargetValue);
    // console.log('?', this.hasDriverChangeAlertTarget)
  }

  toggle(event) {
    let csrf = document.getElementsByName("csrf-token")[0].content;

    if (event.target.checked) {
      let formData = new FormData()
      // formData.append("master_user_input_preferences[use_in]", event.target.value);
      formData.append(`${ this.objectTargetValue }[user_id]`, event.target.dataset.userId);

      formData.append(`${ this.objectTargetValue }[company_id]`, event.target.dataset.companyId);
      formData.append(`${ this.objectTargetValue }[input_option_id]`, event.target.dataset.inputOptionId);

      // console.log('create url', event.target.dataset.url);
      fetch(event.target.dataset.url, {
        body: formData,
        method: 'POST',
        dataType: 'script',
        credentials: "include",
        headers: {
          "X-CSRF-Token": csrf
        },
      }).then(function(response) {
      if (response.status != 204) {
        let el = document.getElementById(`master_pref_${ event.target.value }`)
        el.classList.remove('text-danger')
        el.classList.add('text-success')
        // console.log('response', response);
        // this.driverChangeAlertTarget.innerText = 'Boom!';
        // let splitUrl = response['url'].split('/')
        // let id = splitUrl[splitUrl.length - 1]
        // console.log('id', id);
        // event.target.setAttribute('data-url', `/master_user_input_preferences/${ id }`);
      }
    })

    } else {

      console.log('delete url', event.target.dataset.url);
      fetch(event.target.dataset.url, {
        // body: formData,
        method: 'DELETE',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrf
        },
      }).then(function(response) {
        if (response.status != 204) {
          let el = document.getElementById(`master_pref_${ event.target.value }`)
          el.classList.remove('text-success')
          el.classList.add('text-danger')
        }
      })
    }
  }

  update(event) {
    let csrf = document.getElementsByName("csrf-token")[0].content;
    let formData = new FormData()

    if (event.target.value) {

      formData.append("master_user_input_preference[use_in]", event.target.value);
      // console.log('formData', formData);
      // console.log('url', event.target.dataset.url);

      fetch(event.target.dataset.url, {
        body: formData,
        method: 'PATCH',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrf
        },
      }).then(function(response) {
        if (response.status != 204) {
          event.target.classList.add('faded-bg-info')
        }
      })

    } else {

      fetch(event.target.dataset.url, {
        method: 'DELETE',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrf
        },
      }).then(function(response) {
        // console.log(`response no var: ${ response.status } / ${ response.status == 404 }`)
        if (response.status != 204) {
          event.target.classList.add('faded-bg-danger')
        }
      })
    }
  }

  create(event) {
    let csrf = document.getElementsByName("csrf-token")[0].content;
    let formData = new FormData()
    formData.append(`${ this.objectTargetValue }[use_in]`, event.target.value);
    formData.append(`${ this.objectTargetValue }[company_id]`, event.target.dataset.companyId);
    formData.append(`${ this.objectTargetValue }[input_option_id]`, event.target.dataset.inputOptionId);

    fetch(event.target.dataset.url, {
      body: formData,
      method: 'POST',
      dataType: 'script',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': csrf
      },
    }).then(function(response) {
      if (response.status != 204) {
        event.target.classList.add('faded-bg-success')
      }
    })
  }

}
