import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['thingToShow', 'thingToHide']

  connect() {
  }

  triggerCancel(event) {
    // alert(event.currentTarget.parentNode);
    event.preventDefault(); 
    var wrapper = event.currentTarget.parentNode.parentNode;

    this.style.display = 'none';

    // wrapper.classList.remove('opened');
    // wrapper.classList.add('closed');

    // event.currentTarget.classList.add('slider closed bg-danger');
    // node.parentNode.classList.add('slider closed bg-danger');
    // this.thing_to_hideTargets.forEach( (elem) => {
    //   elem.classList.add('slider closed');
    // });
  }

}
