import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ['offButton']

  // connect() {
  //   alert('boom sha-boom');
  // }

  createOffAndReplaceRow(event) {
     const parent = event.currentTarget.parentNode.parentNode
     fetch(event.currentTarget.getAttribute('data-path'))
      .then(response => response.text())
      .then(html => parent.innerHTML = html)
      // .then(html => this.element.parentNode.parentNode.innerHTML = html)
      // .then(html => console.log(`html: ${ html }`))
  }

}
