import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['expenseVendor', 'expenseAmount', 'expenseMileage']

  connect() { }

  displayMileage(event) {
    var selected = event.currentTarget.options[event.currentTarget.selectedIndex].value
    if (selected == 'mileage') {
      this.expenseMileageTarget.classList.add('d-block')
      this.expenseMileageTarget.classList.remove('d-none')
      console.log(`show expenseMileageTarget`)

      this.expenseVendorTarget.classList.add('d-none')
      this.expenseVendorTarget.classList.remove('d-block')
      console.log(`hide expenseVendorTarget`)

      // this.expenseAmountTarget.value = 0
      // this.expenseAmountTarget.disable = true
      // this.expenseAmountTarget.classList.add('d-none')
      // this.expenseAmountTarget.classList.remove('d-block')
      // console.log(`show expenseAmountTarget`)

    } else {

      this.expenseMileageTarget.classList.add('d-none')
      this.expenseMileageTarget.classList.remove('d-block')
      console.log(`hide expenseMileageTarget`)

      this.expenseVendorTarget.classList.add('d-block')
      this.expenseVendorTarget.classList.remove('d-none')
      console.log(`show expenseVendorTarget`)

      // this.expenseAmountTarget.classList.add('d-block')
      // this.expenseAmountTarget.classList.remove('d-none')
      // console.log(`show expenseAmountTarget`)

      // this.expenseAmountTarget.disable = false
    }
  }

}
