import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['schedule_status', 'service_item_scheduled_wrapper']

  connect() {
    // this.showHideSchedule.bind(this)
    this.showHideSchedule()
  }

  showHideSchedule() {
    // alert('showHideSchedule');
    var wrapper = document.getElementById('service_item_scheduled_wrapper');

    if (this.schedule_status === true) {
      wrapper.classList.remove('opened');
      wrapper.classList.add('closed');
    } else {
      wrapper.classList.remove('closed');
      wrapper.classList.add('opened');
    }

  }

  get schedule_status() {
    return this.schedule_statusTarget.checked
    // document.getElementById("myCheck").checked = true;
  }

}
