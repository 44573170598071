import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['valueField']
  // static values = { clearTarget: String }

  // connect() {
  // }

  loadDataAttrToFieldValue(event) {
    let val = event.currentTarget.options[event.currentTarget.selectedIndex].dataset.value;
    // let numVal = val.valueAsNumber;
    this.valueFieldTarget.value = val;
  };

  // clear(event) {
  //   event.preventDefault();
  //   console.log(`this.clearTargetValue: ${ this.clearTargetValue }`)

  //    var select = document.getElementById(`${ this.clearTargetValue }_select`)
  //    var change = new Event('change');
  //    select.value = null;
  //    select.dispatchEvent(change);
  // };

}
