import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['pilot_time']

  clone(event) {
    if (this.pilot_time_val != '' && this.pilot_time_val != '0.0') {
      event.currentTarget.parentNode.getElementsByTagName( 'input' )[0].value = this.pilot_time_val
    }
  }

  bumpValue(event) {
    document.getElementById( event.currentTarget.dataset.targetInputId ).value = event.currentTarget.dataset.value;
  }

  get pilot_time_val() {
    return this.pilot_timeTarget.value
  }

}
