import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    // alert('connected to remove_modal controller')
    this.modal = new Modal(this.element)
    this.modal.show()
  }

  hideBeforeRender(event) {
    if (this.isOpen()) {
      // alert('is open')
      event.preventDefault()
      this.element.addEventListener('hidden.bs.modal', event.detail.resume)
      this.modal.hide()
    }
  }

  isOpen() {
    return this.element.classList.contains("show")
  }

  close(event) {
    // alert('hit close')
    if (event.detail.success) {
      // alert(`should close`)
      this.modal.hide()
      // var modal_id = event.currentTarget.dataset.modalId

      // var modal = new bootstrap.Modal(document.getElementById(modal_id))
      // if (modal) {
      //   modal.hide();
      // } else {
      //   alert('no modal')
      // }
    }
  }

  forceClose(event) {
    this.modal.hide()
  }

}
