import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['commentList', 'field']

  createSuccess(event) {
    const [_data, _status, xhr] = event.detail

    this.commentListTarget.innerHTML = this.commentListTarget.innerHTML + xhr.response
    this.fieldTarget.value = ''
  }
}
