import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['wrapper', 'project_id', 'project_status']

  // connect() {
  // }

  change_project_status(event) {
    event.preventDefault();
    // console.log(`dataset: ${ event.currentTarget.dataset.projectId }`);
    // alert(`${ event.currentTarget.parentNode.parentNode.dataset.projectId }`);
    // fetch(`/mark_complete?project_id=${ event.currentTarget.parentNode.parentNode.dataset.projectId }&current_user_id=${ event.currentTarget.parentNode.parentNode.dataset.currentUserId }`);
    fetch(`/mark_as?project_id=${ event.currentTarget.dataset.projectId }&mark_as=${ event.currentTarget.dataset.newStatus }&current_user_id=${ event.currentTarget.dataset.currentUserId }`);
    // this.wrapperTarget.classList.add('closed');
    event.currentTarget.parentNode.parentNode.classList.add('closed');
  }

  markInvoicedDirect(event) {
    event.preventDefault();
    // console.log(`/mark_invoiced_direct?project_id=${ event.currentTarget.dataset.projectId }&invoiced_direct_with_customer=${ event.currentTarget.value }`)
    if (event.currentTarget.checked) {
      var val = '1'
    } else {
      var val = '0'
    }
    fetch(`/mark_invoiced_direct?project_id=${ event.currentTarget.dataset.projectId }&invoiced_direct_with_customer=${ val }`);
    // event.currentTarget.parentNode.parentNode.classList.add('closed');
  }

  // mark_not_billable(event) {
  //   event.preventDefault();
  //   fetch(`/mark_not_billable?project_id=${ event.currentTarget.dataset.projectId }`);
  // }

  // markComplete() {
  //   if (this.anonymousTarget.checked) {
  //     this.authorTarget.innerHTML = "it's a secret";
  //   } else {
  //     this.authorTarget.innerHTML = `${ this.author_name }`;
  //   }
  // }

  // update_dom_status() {
  //   this.project_statusTarget.innerHTML = "COMPLETE";
  // }

  // hide_project_alert() {
  //   this.project_statusTarget.style.visibility = "hidden";
  // }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // get project_id() {
  //   return this.wrapperTarget.dataset.projectId
  // }

  // get project_id() {
  //   return this.project_idTarget.value
  // }  

  // get project_status() {
  //   return this.targets.find(`project_status_${ this.wrapperTarget.dataset.projectId }`)
  // }

  // get trigger() {
  //   return this.triggerTarget.value
  // }

}   
