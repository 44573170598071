import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['txTone', 'rxTone', 'txFreq', 'rxFreq', 'toneType', 'freqSignal']

  connect() {
  }

  cloneRx(event) {
    const isChecked = event.currentTarget.checked
    if (isChecked) {
      this.txFreqTarget.value = this.rxFreqTarget.value
      this.txToneTarget.value = this.rxToneTarget.value
    } else {
      this.txFreqTarget.value = ''
      this.txToneTarget.value = ''
    }
  }

  setToneType(event) {
    if (event.currentTarget.value === 'ctcss') {
      this.freqSignalTarget.value = 'analog'
    } else if (event.currentTarget.value === 'dcs') {
      this.freqSignalTarget.value = 'digital'
    }
  }

}
