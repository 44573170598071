import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'mx_hobbs_start_input',
    'mx_hobbs_end_input',
    'rev_hobbs_start_input',
    'rev_hobbs_end_input',
    'mx_hobbs_time_input',
    'rev_hobbs_time_input'
  ]

    // 'mx_hobbs_time_hint',
    // 'rev_hobbs_time_hint'


  // connect () {
  //   alert('hit');
  // }

  copy_mx_hobbs_start_to_hobbs_end() {
    if (this.mx_hobbs_end_inputTarget.value == '') {
      this.mx_hobbs_end_inputTarget.value = `${ this.mx_hobbs_start_val }`;
      this.mx_hobbs_time_inputTarget.value = 0;
    }
  }

  copy_rev_hobbs_start_to_hobbs_end() {
    if (this.rev_hobbs_end_inputTarget.value == '') {
      this.rev_hobbs_end_inputTarget.value = `${ this.rev_hobbs_start_val }`;
      this.rev_hobbs_time_inputTarget.value = 0;
    }
  }

  calc_rev_hobbs() {
    // var calcTotal = parseFloat(this.rev_hobbs_end_val) - parseFloat(this.rev_hobbs_start_val);
    // var roundCalc = calcTotal.toFixed(1);
    this.rev_hobbs_time_inputTarget.value = this.rev_hobbs_calc;
  }

  calc_mx_hobbs() {
    this.mx_hobbs_time_inputTarget.value = this.mx_hobbs_calc;
  }

  validate_mx_hobbs_numbers(event) {
    var hintEl = event.currentTarget.parentNode.getElementsByTagName('small')[0]
    var mxHobbsStart = parseFloat(this.mx_hobbs_start_val);
    // console.log(`mxHobbsStart ${mxHobbsStart}`)
    var mxHobbsEnd = parseFloat(this.mx_hobbs_end_val);
    // console.log(`mxHobbsEnd ${mxHobbsEnd}`)
    var mxHobbVal = parseFloat( this.mx_hobbs_time_inputTarget.value );
    // console.log(`mxHobbVal ${mxHobbVal}`)
    // console.log(`this.mx_hobbs_calc ${this.mx_hobbs_calc}`)
    if (mxHobbsEnd < mxHobbsStart) {
      event.currentTarget.classList.add("border-danger");
      hintEl.classList.remove('text-muted')
      hintEl.classList.add('text-danger')
      event.currentTarget.parentNode.getElementsByTagName('small')[0].innerText = "'Mx Hobbs End' is less than 'Mx Hobbs Start' - must be equal to or greater than";

    } else {
      // console.log(`VALID - mx hobbs are appropriate`)
      hintEl.classList.remove('text-danger')
      hintEl.classList.add('text-muted')
      event.currentTarget.classList.remove('border-danger')
      // hintEl.classList.add('border-danger')
      hintEl.innerText = "";
    }

  }

  validate_mx_hobbs_time(event) {
    var val = parseFloat( this.mx_hobbs_time_inputTarget.value );
    // var hintEl = event.currentTarget.parentNode.getElementsByTagName('small')[0]
    var hintEl = this.mx_hobbs_time_inputTarget.parentNode.getElementsByTagName('small')[0]

    if (val != this.mx_hobbs_calc) {
      event.currentTarget.classList.add("border-danger");
      hintEl.classList.remove('text-muted')
      hintEl.classList.add('text-danger');
      hintEl.innerText = "this value doesn't equal 'Mx Hobbs End' - 'Mx Hobbs Start'.  Check your math!";
    } else if (val < 0) {
      event.currentTarget.classList.add("border-danger");
      hintEl.classList.remove('text-muted')
      hintEl.classList.add('text-danger');
      hintEl.innerText = "this value appears to be a negative number.  Check your math!";

    } else {
      event.currentTarget.classList.remove("border-danger");
      hintEl.classList.add('text-muted')
      event.currentTarget.classList.remove('border-danger')
      event.currentTarget.classList.add('border-success')
      hintEl.classList.remove('text-danger');
      hintEl.classList.add('text-success');
      hintEl.innerText = "Math looks good.  Nice work!";
    }

  }

  validate_rev_hobbs_time(event) {
    var val = parseFloat( this.rev_hobbs_time_inputTarget.value );
    var hintEl = event.currentTarget.parentNode.getElementsByTagName('small')[0]

    if (val != this.rev_hobbs_calc) {
      event.currentTarget.classList.add("border-danger");
      hintEl.classList.remove('text-muted')
      hintEl.classList.add('text-danger');
      hintEl.innerText = "this value doesn't equal 'Rev Hobbs End' - 'Rev Hobbs Start'.  Check your math!";
    } else if (val < 0) {
      event.currentTarget.classList.add("border-danger");
      hintEl.classList.remove('text-muted')
      hintEl.classList.add('text-danger');
      hintEl.innerText = "this value appears to be a negative number.  Check your math!";

    } else {
      event.currentTarget.classList.remove("border-danger");
      hintEl.classList.add('text-muted')
      event.currentTarget.classList.remove('border-danger')
      event.currentTarget.classList.add('border-success')
      hintEl.classList.remove('text-danger');
      hintEl.classList.add('text-success');
      hintEl.innerText = "Math looks good.  Nice work!";
    }

  }

  get mx_hobbs_start_val() {
    return this.mx_hobbs_start_inputTarget.value
  }

  get mx_hobbs_end_val() {
    return this.mx_hobbs_end_inputTarget.value
  }

  get mx_hobbs_calc() {
    var val = parseFloat(this.mx_hobbs_end_val) - parseFloat(this.mx_hobbs_start_val);
    return val.toFixed(1);
  }

  get rev_hobbs_start_val() {
    return this.rev_hobbs_start_inputTarget.value
  }

  get rev_hobbs_end_val() {
    return this.rev_hobbs_end_inputTarget.value
  }

  get rev_hobbs_calc() {
    var val = parseFloat(this.rev_hobbs_end_val) - parseFloat(this.rev_hobbs_start_val);
    return val.toFixed(1);
  }


}
