import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['monthly_rate_flight_hours_base']

  // connect() {
  //   alert('xxx');
  // }

  show_additional_fields(event) {
    const thisSelected = event.currentTarget.options[event.currentTarget.selectedIndex].value
    if (thisSelected == 'monthly_rate') {
      this.monthly_rate_flight_hours_baseTarget.classList.remove('d-none')
    } else {
      this.monthly_rate_flight_hours_baseTarget.classList.add('d-none')
    }
  }

}
