import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'moneyToggle',
    'moneyValue',
    'moneyToggleText',
    'taxLocation',
    'taxLocationWrapper',
    'projectAutoScheduleCheckBox',
    'projectAutoScheduleWrapper',
    'crewList',
    'equipmentAssetDatesWrapper',
    'equipmentAssetDatesText',
    'outsideTrainerWrapper',
    'trainer',
    'unavailabilityInput',
    'moneyValueNote',
    'aircraftModelFamily',
    'trainerWrapper',
    'moneyValueOperation',
    'groundTrainingDurationWrapper',
    'userHourlyOrNonExemptSalary',
    'dailyLogDutyTimesCard',
    'aircraftTotalsWrapper',
    'aircraftTotalsRequiredItem',
    'projectScheduleUpdateCrewScheduleId'
  ]

  connect() {
    // alert('shit')
    // if (this.hasAircraftModelFamilyTarget) {
    //   var trainerVal = this.trainerTarget.value
    // } else {
    //   var trainerVal = null
    // }

    if (this.hasTrainerTarget) {
      var trainerVal = this.trainerTarget.value
    } else {
      var trainerVal = null
    }

    if (this.hasTrainerTarget) {
      if (trainerVal == '') {
        this.outsideTrainerWrapperTarget.classList.add('visible');
        this.outsideTrainerWrapperTarget.classList.remove('invisible');
      } else {
        this.outsideTrainerWrapperTarget.classList.add('invisible');
        this.outsideTrainerWrapperTarget.classList.remove('visible');
      }
    }
  }

  toggleAircraftTotals(event) {
    const isChecked = event.currentTarget.checked
    if (isChecked) {
      this.aircraftTotalsWrapperTarget.classList.add('d-none')
      this.aircraftTotalsWrapperTarget.classList.remove('visible')

      this.aircraftTotalsRequiredItemTargets.forEach((el, i) => {
        el.removeAttribute('required');
      })

    } else {
      this.aircraftTotalsWrapperTarget.classList.remove('d-none')
      this.aircraftTotalsWrapperTarget.classList.add('visible')

      this.aircraftTotalsRequiredItemTargets.forEach((el, i) => {
        el.addAttribute('required');
      })

    }
  }

  toggleAircraftModelFamily(event) {
    const val = event.currentTarget[event.currentTarget.selectedIndex].innerText
    if (val.includes("Compliance")) {
      this.aircraftModelFamilyTarget.classList.add('d-none');
      this.aircraftModelFamilyTarget.classList.remove('visible');
      this.trainerWrapperTarget.classList.add('d-none');
      this.trainerWrapperTarget.classList.remove('visible');
      this.outsideTrainerWrapperTarget.classList.add('d-none');
      this.outsideTrainerWrapperTarget.classList.remove('visible');

    // } else if (val.includes("iat.gov")) {
    //   this.groundTrainingDurationWrapperTarget.classList.add('d-none');

    } else {
      this.aircraftModelFamilyTarget.classList.add('visible');
      this.aircraftModelFamilyTarget.classList.remove('d-none');
      this.trainerWrapperTarget.classList.add('visible');
      this.trainerWrapperTarget.classList.remove('d-none');
      this.outsideTrainerWrapperTarget.classList.add('visible');
      this.outsideTrainerWrapperTarget.classList.remove('d-none');
      this.groundTrainingDurationWrapperTarget.classList.remove('visible');
    }

  }

  toggleMoney(event) {
    const isChecked = event.currentTarget.checked
    // alert(`${ isChecked }`);
    this.moneyValueTargets.forEach((el, i) => {
      if (isChecked) {
        el.innerText = el.dataset.secretValue;
        this.moneyToggleTextTarget.innerText = 'Hide Money';
      } else {
        el.innerText = 'xxx.xx';
        this.moneyToggleTextTarget.innerText = 'Show Money';
      }
    })
  }

  hoverToggleMoney(event) {
    // alert(event.currentTarget.dataset.secretValue)
    event.currentTarget.innerText = event.currentTarget.dataset.secretValue;
  }

  toggleTaxLocation(event) {
    const isChecked = event.currentTarget.checked

    if (isChecked) {
      this.taxLocationTarget.disabled = false;
      this.taxLocationWrapperTarget.classList.remove('invisible');
      this.taxLocationWrapperTarget.classList.add('visible');
    } else {
      // alert('show it');
      this.taxLocationTarget.disabled = true;
      this.taxLocationWrapperTarget.classList.remove('visible');
      this.taxLocationWrapperTarget.classList.add('invisible');
    }
  }

  toggleProjectAutoSchedule() {
    const isChecked = this.projectAutoScheduleCheckBoxTarget.checked

    if (isChecked) {
      this.projectAutoScheduleWrapperTarget.classList.remove('invisible');
      this.projectAutoScheduleWrapperTarget.classList.add('visible');
    } else {
      this.projectAutoScheduleWrapperTarget.classList.remove('visible');
      this.projectAutoScheduleWrapperTarget.classList.add('invisible');
    }
  }

  toggleCommentCrew(event) {
    if (this.hasCrewListTarget) {
      const isChecked = event.currentTarget.checked
      if (isChecked) {
        this.crewListTarget.classList.add('invisible');
        this.crewListTarget.classList.remove('visible');
      } else {
        this.crewListTarget.classList.remove('invisible');
        this.crewListTarget.classList.add('visible');
      }
    }
  }

  toggleForMany(event) {
    const quantityVal = event.currentTarget.value
    if (quantityVal > 1) {
      // alert(`quantityVal: ${ quantityVal }`)
      this.equipmentAssetDatesTextTarget.innerText = `Dates not applicable because you are accounting for ${ quantityVal } of the same type of item...`;
      this.equipmentAssetDatesWrapperTarget.classList.add('invisible');
      // this.crewListTarget.classList.remove('visible');
    } else {
      this.equipmentAssetDatesWrapperTarget.classList.remove('invisible');
      this.equipmentAssetDatesTextTarget.innerText = '';
      // this.crewListTarget.classList.add('visible');
    }
  }

  toggleOutsideTrainer(event) {
    const val = event.currentTarget.value
    if (this.hasOutsideTrainerWrapperTarget) {
      if (val == '') {
        this.outsideTrainerWrapperTarget.classList.add('visible');
        this.outsideTrainerWrapperTarget.classList.remove('invisible');
      } else {
        this.outsideTrainerWrapperTarget.classList.add('invisible');
        this.outsideTrainerWrapperTarget.classList.remove('visible');
      }
    }
  }

  toggleUnavailability(event) {
    const isChecked = event.currentTarget.checked
    if (isChecked) {
      this.unavailabilityInputTarget.classList.add('collapse.show');
      this.unavailabilityInputTarget.classList.remove('collapse');
    } else {
      this.unavailabilityInputTarget.classList.add('collapse');
      this.unavailabilityInputTarget.classList.remove('collapse.show');
    }
  }

  togglePayrollValueFields(event) {
    const val = event.currentTarget[event.currentTarget.selectedIndex].innerText
    if (val == 'Other Day Rate') {
      this.moneyValueNoteTarget.classList.add('d-inline-block');
      this.moneyValueNoteTarget.classList.remove('d-none');

      this.moneyValueOperationTarget.classList.add('d-inline-block');
      this.moneyValueOperationTarget.classList.remove('d-none');
    } else {
      this.moneyValueNoteTarget.classList.add('d-none');
      this.moneyValueNoteTarget.classList.remove('d-inline-block');

      this.moneyValueOperationTarget.classList.add('d-none');
      this.moneyValueOperationTarget.classList.remove('d-inline-block');
    }
  }

  toggleDutyTimes(event) {
    const selectVal = event.currentTarget[event.currentTarget.selectedIndex].value
    const val = this.userHourlyOrNonExemptSalaryTarget.dataset.value
    if ( (selectVal == 'travel_day' && val == 'true') || (selectVal == 'day_off') || (selectVal == 'paid_non_duty_day') || (selectVal == 'holiday') ) {
      this.dailyLogDutyTimesCardTarget.classList.remove('d-visible')
      this.dailyLogDutyTimesCardTarget.classList.add('d-none')
    } else {
      this.dailyLogDutyTimesCardTarget.classList.remove('d-none')
      this.dailyLogDutyTimesCardTarget.classList.add('d-visible')
    }
  }

  showProjectScheduleCrew(event) {
    const selectVal = event.currentTarget[event.currentTarget.selectedIndex].value
    // alert(`${event.currentTarget.dataset.savedAircraftId} == ${selectVal}`)
    if ( event.currentTarget.dataset.savedAircraftId == selectVal ) {
      // alert('should be the original aircraft')
      this.projectScheduleUpdateCrewScheduleIdTarget.classList.add('d-none')
      this.projectScheduleUpdateCrewScheduleIdTarget.classList.remove('d-visible')
    } else {
      // alert('should be a new aircraft')
      this.projectScheduleUpdateCrewScheduleIdTarget.classList.add('d-visible')
      this.projectScheduleUpdateCrewScheduleIdTarget.classList.remove('d-none')
    }
  }

}
