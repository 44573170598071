import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'riskScore', 'raForm', 'riskScoreBar', 'riskLevel', 'raStatus', 'scoreBarValue', 'raSelect', 'raCheckBox' ]
  static values = { page: String }

  connect() {
    // const onLoadScore = this.riskScoreTarget.value
    // const maxScore =
    // console.log(onLoadScore);
    // console.log(maxScore);

    if (this.pageValue == 'risks') {
      this.calculateRiskScore()
    } else if (this.pageValue == 'mitigations') {
      this.displayRiskScore( this.riskScoreTarget.value )
    }
  }

  get onLoadScore() {
    return this.riskScoreTarget.value
  }

  get maxScore() {
    // console.log(`maxScore method ${ this.raFormTarget.dataset.maxScore }`)
    return this.raFormTarget.dataset.maxScore
  }

  addGuidance(event) {
    const thisSelect = event.currentTarget.options[event.currentTarget.selectedIndex]
    var penalty = thisSelect.dataset.penalty
    var val = thisSelect.value
    const wrapper = thisSelect.parentNode.parentNode
    var guidance = thisSelect.dataset.guidance

    wrapper.getElementsByClassName('help-guidance').forEach(el => el.remove())
    if (typeof guidance !== 'undefined') {
      wrapper.insertAdjacentHTML( 'beforeend', `<span class="help-block help-guidance text-muted text-sm">${ guidance }</span>`)
    }
  }

  adjustProgressBar(score) {
    var barVal = ( parseInt(score) / parseInt(this.maxScore) )*100;
    var newWidth = Math.min(barVal, 100.5)
    // set the hidden form field risk_score value
    this.riskScoreTarget.value = score;

    // display the current risk value inside the progress bar
    this.riskScoreBarTarget.innerText = score

    // update the displayed risk value
    this.scoreBarValueTarget.innerText = score

    // set attributes
    this.riskScoreBarTarget.setAttribute('aria-valuenow', newWidth)
    this.riskScoreBarTarget.style.cssText = `width: ${ newWidth }%; border-right: thick solid #555;`
    // this.riskScoreBarTarget.style.width = `${ newWidth }%;`
  }

  calculateRiskScore() {
    const debt = this.raFormTarget.dataset.debt || 0;
    let initialRiskScore = parseInt(0 + debt);
    let initialRiskScoreString = '';
    // console.log(`debt: ${ debt }`)
    // console.log(`initialRiskScore: ${ initialRiskScore }`)

    this.raCheckBoxTargets.forEach((el, i) => {
      if (el.checked) {
        initialRiskScore += parseInt( el.dataset.penalty || 0 );
        initialRiskScoreString += `{${el.dataset.penalty}  [${ el.parentNode.getElementsByTagName('label')[0].textContent }]}, `;
      }
    })

    this.raSelectTargets.forEach((el, i) => {
      initialRiskScore += parseInt( el.options[el.selectedIndex].dataset.penalty || 0 )
      initialRiskScoreString += `{${el.options[el.selectedIndex].dataset.penalty} [${ el.options[el.selectedIndex].text }]}, `
    })

    // console.log(`initialRiskScoreString: ${initialRiskScoreString}`)
    this.displayRiskScore(initialRiskScore);
  };

  displayRiskScore(score) {
    // alert(score);
    // this.riskScoreTarget.value = score;
    this.adjustProgressBar(score);
    this.updateRiskLevel(score);
  };

  getRiskLevel(score) {
    let scoreLevel;
    const max = this.maxScore
    const elevatedScore = max * 0.5;
    const lowerModerateScore = max * 0.7;
    const upperModerateScore = max * 0.9;
    const extremeScore = max; //  * 1.3

    if (score < elevatedScore) {
      scoreLevel = 'low'
    } else if ( (score >= elevatedScore) && (score < lowerModerateScore) ) {
      scoreLevel = 'elevated'
    } else if ( (score >= lowerModerateScore) && (score <= upperModerateScore) ) {
      scoreLevel = `moderate` // ${score} >= ${lowerModerateScore}
    } else if ( (score > upperModerateScore) && (score <= max) ) {
      scoreLevel = 'high'
    } else if ( score >= extremeScore ) {
      scoreLevel = 'extreme'
    } else {
      scoreLevel = ''
    }
    return scoreLevel
  };

  updateRiskLevel(score) {
    var level = this.getRiskLevel(score)
    // console.log(`getRiskLevel(score) ${ level }`)
    this.riskLevelTarget.value = level;
    this.raStatusTarget.innerText = `(${ level.toUpperCase() })`;
  }

  applyCredit(event) {
    let score, scoreValue;
    const initialScore = parseInt( this.onLoadScore );
    const existingRaItem = parseInt( document.getElementById(`penalty_for_selected_ra_item_${ event.currentTarget.dataset.selectedRaItemId }`).textContent );
    const penaltyCredit = parseInt( event.currentTarget.dataset.penaltyCredit || 0 );

    if ( event.currentTarget.checked ) {
      scoreValue = existingRaItem - penaltyCredit;
      score = initialScore - penaltyCredit;
    } else {
      scoreValue = existingRaItem + penaltyCredit;
      score = initialScore + penaltyCredit;
    }

    this.displayRiskScore(score)
    document.getElementById(`penalty_for_selected_ra_item_${ event.currentTarget.dataset.selectedRaItemId }`).innerText = scoreValue;
    this.riskScoreTarget.value = score
  }

}
