import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // expense_code
  static targets = ['expense_id', 'odometer_at_service', 'odometer_next_due', 'service_record_expense_display', 'expense_from_list']
  connect() {
  }

  update_odometer_next_due() {
    var odometer_next_due = (this.odometer_at_service + this.mileage_interval).toFixed(1);
    this.odometer_next_dueTarget.value = odometer_next_due
  }

  // lookup_expense_by_code() {
  //   if (this.expense_code.length > 0) {
  //     this.service_record_expense_displayTarget.innerText = 'searching for expense...'
  //     fetch(`/find_expense?expense_code=${ this.expense_code }`).
  //     then(response => response.json()).
  //     then(stuff => this.service_record_expense_displayTarget.innerText = stuff)
  //   } else {
  //     this.service_record_expense_displayTarget.innerText = 'no expense code given...'
  //   }
  // }

  lookup_expense_from_list(event) {
    event.preventDefault();    
    var expense_id = event.currentTarget.dataset.expenseId;

    if (expense_id.length > 0) {
      this.expense_idTarget.value = expense_id
      this.service_record_expense_displayTarget.innerText = 'searching for expense...'
      // fetch(`/find_expense?expense_code=${ dataset_code }`).
      fetch(`/find_expense?expense_id=${ expense_id }`).
        then(response => response.json()).
        then(stuff => this.service_record_expense_displayTarget.innerText = stuff)
      expense_id = null
    } else {
      this.service_record_expense_displayTarget.innerText = 'no expense given...'
      expense_id = null
    }
  }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // get expense_code() {
  //   return this.expense_codeTarget.value
  // }

  get odometer_at_service() {
    // return self.odometer_at_serviceTarget.value
    return parseFloat(this.odometer_at_serviceTarget.value)
  }

  get mileage_interval() {
    // return self.odometer_at_serviceTarget.dataset.mileage_interval
    return parseFloat(this.data.get('mileage-interval'))
  }

  get expense_from_list() {
    return this.expense_from_listTarget
  }

}   
