import { Controller } from "@hotwired/stimulus"

/* This is your application's ApplicationController.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 */
export default class extends Controller {
  connect () {
  }
}
